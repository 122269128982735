// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import "~bootstrap/scss/bootstrap";

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/utilities";

@each $color, $value in $colors {
    .bg-#{$color}{
        background-color: $value !important;
    }

    .btn-#{$color}{
        color: #fff !important;
        background-color: $value !important;;
        border-color: $value !important;
      }
    .btn-#{$color}:hover{
        color: #fff !important;
        background-color: $value !important;;
        border-color: $value !important;
    }
    .btn-outline-#{$color}{
        color: $value !important;
        border-color: $value !important;
      }
    .btn-outline-#{$color}:hover{
        color: #fff !important;
        background-color: $value !important;
        border-color: $value !important;
    }
    .btn-outline-#{$color}:focus{
        box-shadow: 0 0 0 0.25rem rgba(red($value), green($value), blue($value), 0.5) !important;

        
    }
    .btn-check:checked + .btn-outline-#{$color}, .btn-check:active + .btn-outline-#{$color}, .btn-outline-#{$color}:active, .btn-outline-#{$color}.active, .btn-outline-#{$color}.dropdown-toggle.show{
      color: #fff !important;
      background-color: $value !important;
      border-color: $value !important;
  }
  }
//FontAwesome
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import "~select2/dist/css/select2.css";
@import "~select2-bootstrap-5-theme/src/include-all";